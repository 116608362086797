<template>
  <div class="dialog-form">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="addStaffRules"
      label-width="100px"
      class="demo-ruleForm"
      :size="formSize"
    >
      <el-form-item label="姓名" prop="userRealname">
        <el-input placeholder="请输入真实姓名" v-model="ruleForm.userRealname"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="userMobile">
        <el-input placeholder="请输入手机号" v-model="ruleForm.userMobile"></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="imgUploadRef">
        <upload-img
          :limit="1"
          :fileList="staffAvatar"
          ref="imgUploadRef"
          @handleUploadSuccess="handleImgUploadSuccess"
          @handleUploadRemove="handleUploadRemove"
        ></upload-img>
        <div class="tips">建议尺寸750 * 750px</div>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-select
          v-model="ruleForm.gender"
          class="m-2"
          placeholder="选择性别"
          size="large"
        >
          <el-option
            v-for="item in genderList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="岗位" prop="jobLevel">
        <el-select
          @change="handleJobLevelChange"
          v-model="ruleForm.jobLevel"
          class="m-2"
          placeholder="选择岗位"
          size="large"
        >
          <el-option
            v-for="item in staffPositionList"
            :key="item.lvId"
            :label="item.levelName"
            :value="item.lvId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职级" prop="subJobLevel">
        <el-select
          :disabled="typeof ruleForm.jobLevel == 'number' ? false : true"
          v-model="ruleForm.subJobLevel"
          class="m-2"
          :placeholder="typeof ruleForm.jobLevel == 'number' ? '选择职位' : '先选择职位'"
          size="large"
        >
          <el-option
            v-for="item in staffChildRankList"
            :key="item.lvId"
            :label="item.levelName"
            :value="item.lvId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="擅长" prop="userTags">
        <el-input
          placeholder="请输入擅长服务,以逗号分隔"
          v-model="ruleForm.userTags"
        ></el-input>
      </el-form-item>

      <el-form-item label="入职时间" prop="joinTime">
        <el-date-picker
          v-model="ruleForm.joinTime"
          type="date"
          placeholder="请选择日期"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="介绍" prop="name">
        <el-input
          :rows="4"
          type="textarea"
          maxlength="100"
          placeholder="请输入个性签名"
          show-word-limit
          v-model="ruleForm.userPersonInfo"
        ></el-input>
      </el-form-item>
      <div class="item_box">
        <div class="flex_box">
          <p class="titles">门店信息</p>
          <el-button type="primary" @click="handleAddShopInfo">新增</el-button>
        </div>
        <el-table :data="ruleForm.itemList">
          <el-table-column prop="itemList" label="*部门/门店" width="300" align="center">
            <template #default="scope">
              <el-form-item label-width="0px" :prop="'itemList.' + scope.$index + '.dpId'"
              :rules="{
                required: true,
                message: '请选择部门/门店',
                trigger: 'change',
              }">
                  <el-select v-model="ruleForm.itemList[scope.$index].dpId" class="m-2" placeholder="选择部门/门店" @change="handleShopChange($event,scope.$index)">
                    <el-option v-for="option in departmentList" :key="option.dpId" :label="option.dpName" :value="option.dpId" />
                  </el-select>
                </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="itemList" label="*关联角色" width="300" align="center">
            <template #default="scope">
              <el-form-item label-width="0px" :prop="'itemList.' + scope.$index + '.roleIds'"
              :rules="{
                required: true,
                message: '请选择角色',
                trigger: 'change',
              }">
                <el-select :disabled="!scope.row.dpId" multiple v-model="ruleForm.itemList[scope.$index].roleIds" class="m-2" placeholder="选择角色">
                  <el-option v-for="option in scope.row.allRoleLists" :key="option.roleId" :label="option.roleName" :value="option.roleId" />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="scope">
              <el-button size="small" :disabled="ruleForm.itemList&&ruleForm.itemList.length==1" type="danger" @click="removeTableItem(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>
    <div class="save-btn">
      <el-button type="primary" @click="handleSaveClick(ruleFormRef)">保存</el-button>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { rankProps, genderList } from './config'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { addStaffRules } from './config'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import { editEmp, getStaffChildRankList,getAllRoleList } from '@/service/main/staff'
import { useRoute } from 'vue-router'
import router from '@/router'
import emitter from '@/utils/eventbus'
import { getShopList } from '@/service/main/reserve'

export default defineComponent({
  props: {
    staffId: {
      type: Number,
      default: 1
    }

  },
  components: {
    UploadImg
  },
  emits: [],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const staffDetail = computed(() => store.state.staff.staffDetail)
    const staffAvatar = ref([])

    watch(staffDetail, async (n) => {
      if (n != null) {
        ruleForm.userMobile = n.userMobile
        ruleForm.userRealname = n.userRealname
        ruleForm.joinTime = n.joinTime
        ruleForm.userPersonInfo = n.userPersonInfo
        ruleForm.userPhoto = n.userPhoto
        ruleForm.gender = String(n.gender)
        ruleForm.userTags = n.userTags
        ruleForm.jobLevel = n.jobLevel?Number(n.jobLevel):''
        if (n.jobLevel) {
          handleJobLevelChange(n.jobLevel)
        }
        ruleForm.subJobLevel = n.subJobLevel?Number(n.subJobLevel):''
        staffAvatar.value = [
          {
            name: n.userRealname + '的头像',
            url: n.userPhoto
          }
        ]
        ruleForm.itemList=n.itemList
        ruleForm.itemList.map((item,index)=>{
          getShopChange(item.dpId,index)
        })
      }
    })
    const getShopChange = async(e,index) =>{
      let isShop = departmentList.value.filter(item=>{return item.dpId==e})[0].isShop
      const res = await getAllRoleList(isShop)
      ruleForm.itemList[index].allRoleLists = res.data
    }
    const handleShopChange = async(e,index) =>{
      let isShop = departmentList.value.filter(item=>{return item.dpId==e})[0].isShop
      const res = await getAllRoleList(isShop)
      ruleForm.itemList[index].roleIds = []
      ruleForm.itemList[index].allRoleLists = res.data
    }
    const handleAddShopInfo = () =>{
      ruleForm.itemList.push({dpId:'',roleIds:[]})
    }
    const removeTableItem = (index) =>{
      ruleForm.itemList.splice(index,1)
    }

    const ruleFormRef = ref()

    const initPage = () => {
      store.dispatch('staff/getStaffPositionAction', COMPANY_ID)
      store.dispatch('staff/getStaffDeparentAction', {
        companyId: COMPANY_ID
      })
      store.dispatch('staff/getStaffDetailAction', [route.query.staffId, COMPANY_ID])
    }
    initPage()

    const handleUploadRemove = (lastUrl) => {
      ruleForm.userPhoto = ''
      staffAvatar.value = []
    }

    watch(() => route.query.staffId, () => {
      initPage()
    }, {
      deep: true
    })

    const ruleForm = reactive({
      userMobile: '',
      userRealname: '',
      joinTime: '',
      userPersonInfo: '',
      userPhoto: '',
      gender: '',
      jobLevel: '',
      subJobLevel: '',
      userTags: '',
      itemList:[{dpId:'',roleIds:[]}]
    })
    const ruleFormRank = ref('')

    const imgUploadRef = ref('')
    const handleImgUploadSuccess = (res) => {
      ruleForm.userPhoto = res.response.data.fileName
    }

    const handleRankChange = (value) => {
      ruleForm.jobLevel = value[0]
      if (value.length == 2) {
        ruleForm.subJobLevel = value[1]
      }
    }

    const emptyForm = () => {
      ruleFormRank.value = ''
      imgUploadRef.value.emptyFileList()
      for (let key in ruleForm) {
        if (key != 'companyId') {
          ruleForm[key] = ''
        }
      }
    }

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          if (ruleForm.userPhoto.length == 0) {
            ElMessage({
              message: '请上传员工头像',
              type: 'warning',
            })
            return
          }
          let userTags = ruleForm.userTags
          userTags = userTags.replace(/，/g, ",")
          ruleForm.userTags = userTags
          let obj = JSON.parse(JSON.stringify(ruleForm))
          obj.joinTime = dayjs(obj.joinTime).format('YYYY-MM-DD')
          obj.itemList.map(item=>{
            delete item.allRoleLists
          })
          // console.log(obj);
          const res = await editEmp({...obj,userId: route.query.staffId})
          if (res.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })
            router.go(-1)
            setTimeout(() => {
              emitter.emit('refreshStaffListPageContent')
            }, 1000);
          }else{
            ElMessage({
              message: res.msg,
              type: 'error',
            })
          }
        } else {
          ElMessage({
            message: '带*为必填项,请正确填写数据',
            type: 'warning',
          })
        }
      })
    }

    const staffPositionList = computed(() => store.state.staff.staffPositionList)
    const departmentList = computed(() => store.state.staff.departmentList)
    const staffChildRankList = ref([])

    const handleJobLevelChange = async (value) => {
      if(!value) return
      ruleForm.subJobLevel = ''
      let res = await getStaffChildRankList(value)
      staffChildRankList.value = res.data.list

    }
    const handleDepClick = (item) => {
      ruleForm.shopId = item.shopId
	  ruleForm.isShop = item.isShop
	  ruleForm.roleIds = []
	  store.dispatch('staff/getAllRoleLists', ruleForm.isShop)
    }

    return {
      handleDepClick,
      handleJobLevelChange,
      staffPositionList,
      ruleForm,
      imgUploadRef,
      COMPANY_ID,
      handleImgUploadSuccess,
      ruleFormRank,
      handleRankChange,
      handleSaveClick,
      rankProps,
      departmentList,
      genderList,
      staffChildRankList,
      staffDetail,
      staffAvatar,
      UPLOAD_BASE_URL,
      handleUploadRemove,
      addStaffRules,
      ruleFormRef,
      dayjs,
      handleAddShopInfo,
      removeTableItem,
      handleShopChange
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  padding: 20px;
  margin: 20px;
  background-color: #fff;

  .tips {
    font-size: 12px;
    color: rgb(40, 40, 40);
  }

  .save-btn {
    text-align: right;
    max-width: 800px;
  }

  &:deep(.demo-ruleForm) {
    max-width: 800px;
  }

  &:deep(.el-upload) {
    width: 100px;
    height: 100px;
  }

  &:deep(.el-upload--picture-card i) {
    margin-top: 34px;
  }

  &:deep(.el-upload-list) {
    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
  }

  &:deep(.el-form-item) {
    .el-select,
    .el-cascader {
      width: 100% !important;
      max-width: 100% !important;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }
  .titles{
    padding:0 10px;
    margin: 20px 0;
    border-left: 5px solid #7948ea;
    font-size: 14px;
  }
  .item_box{
    margin-bottom: 20px;
    .flex_box{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &:deep(.el-form-item) {
      margin-bottom: 0px;
    }
  }
}
</style>
